import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import { Formik, Form, Field } from 'formik';
import { connect, useDispatch } from 'react-redux';
import { TextField } from 'formik-material-ui';
import { Link } from 'gatsby-theme-material-ui';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import { getOr } from 'lodash/fp';

import { useParams } from '@reach/router';
import { useTranslation } from 'react-i18next';
import CustomInputField from '../custom/CustomInputField';
import { SignInValidation } from './validation';
import { auth, alert, stats, enterprise } from '../../state';

const SignInForm = ({ login, setAlert }) => {
  const dispatch = useDispatch();
  const setUser = (payload) => dispatch(auth.actions.setUser(payload));
  const updateUser = (payload) => dispatch(auth.actions.updateUser(payload));
  const getEnterpriseDetails = () => dispatch(enterprise.actions.getInfo());
  const { id } = useParams();
  const { t } = useTranslation();

  return (
    <Formik
      enableReinitialize
      validationSchema={SignInValidation}
      initialValues={{
        email: '',
        password: '',
      }}
      onSubmit={async (values, actions) => {
        const result = await login(values);

        const token = getOr('', ['payload', 'jwtToken'], result);
        const role = getOr('', ['payload', 'role'], result);
        const error = getOr('', ['error', 'message'], result);
        if (error) {
          setAlert({ message: error, severity: 'error' });
          actions.setErrors({ email: ' ', password: ' ' });
        }
        if (!error && role !== 'EnterpriseOwner') {
          setAlert({
            message: 'This login is intended for merchants only',
            severity: 'error',
          });
          setUser({});
          return;
        }
        if (role === 'EnterpriseOwner' && !error && token) {
          if (id) {
            updateUser({ merchantId: id });
          }
          navigate('/dashboard');
          await getEnterpriseDetails();
        }
      }}
    >
      {({ isSubmitting, handleSubmit }) => {
        return (
          <Form>
            <Box pb={2}>
              <CustomInputField
                customInput={Field}
                component={TextField}
                trim
                fullWidth
                required
                type="email"
                label={t('email')}
                name="email"
                variant="outlined"
              />
            </Box>
            <Box>
              <Field
                component={TextField}
                fullWidth
                required
                type="password"
                label={t('password')}
                name="password"
                variant="outlined"
              />
            </Box>
            <Box align="right" pb={2}>
              <Link to="/forgot-password">
                <Typography variant="caption" color="textSecondary">
                  {t('forgotPassword')}?
                </Typography>
              </Link>
            </Box>
            <Box pb={2}>
              <Button
                fullWidth
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                onClick={handleSubmit}
                size="large"
              >
                {isSubmitting ? <CircularProgress size={24} /> : t('signIn')}
              </Button>
            </Box>
            {/* <Box align="center"> */}
            {/*   <Link to="mailto:enterprise@pintuna.com"> */}
            {/*     <Typography variant="caption" color="textSecondary"> */}
            {/*       Contact enterprise@pintuna.com to register */}
            {/*     </Typography> */}
            {/*   </Link> */}
            {/* </Box> */}
          </Form>
        );
      }}
    </Formik>
  );
};

SignInForm.propTypes = {
  login: PropTypes.func.isRequired,
  setAlert: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  login: (payload) => dispatch(auth.actions.login(payload)),
  setAlert: (payload) => dispatch(alert.actions.open(payload)),
  getStats: (payload) => dispatch(stats.actions.getStats(payload)),
});

export default connect(null, mapDispatchToProps)(SignInForm);
