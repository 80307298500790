import React, { useEffect } from 'react';
import { CardMedia, Box, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'gatsby';
import { navigate } from '@reach/router';
import { isEmpty } from 'lodash/fp';

import { Skeleton } from '@material-ui/lab';
import SEO from '../components/seo';
import { ResetPasswordForm } from '../components/Auth';
import { Header, Content } from '../components/layouts';
import logoPath from '../images/logo.png';
import { auth, businesses } from '../state';
import { PreLoader } from '../components/custom';

const ResetPassword = () => {
  const user = useSelector((state) => auth.selectors.selectUser(state));

  React.useEffect(() => {
    if (isEmpty(user)) {
      navigate('/');
    }
  });

  if (isEmpty(user)) {
    return <PreLoader />;
  }

  const merchant = useSelector(businesses.selectors.selectMerchant);
  const isLoading = useSelector(businesses.selectors.selectIsLoading);
  const dispatch = useDispatch();

  const fetchMerchant = (p) => dispatch(businesses.actions.fetchMerchant(p));
  const host = typeof window !== 'undefined' ? window?.location?.origin : '';
  const hostNames =
    typeof window !== 'undefined' ? window?.location?.hostname?.split('.') : [];
  useEffect(() => {
    if (host && !host.includes('pintuna')) {
      fetchMerchant(encodeURIComponent(host));
    } else if (hostNames?.length > 3) {
      fetchMerchant(hostNames[0]);
    }
  }, []);

  return (
    <>
      <SEO title="Reset Password" />
      <Header noMenu backArrow href="/forgot-password" />
      <Content md={4} alignMiddle>
        <Box pb={2} align="center">
          <Link to="/">
            {isLoading && merchant ? (
              <Skeleton
                variant="rect"
                style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  paddingTop: 'calc(20% + 19px)',
                  backgroundSize: 'contain',
                  borderRadius: '16px',
                }}
              />
            ) : (
              <CardMedia
                src={merchant.logoUrl || logoPath}
                component="img"
                alt="Pintuna logo"
                style={{ width: '200px' }}
              />
            )}
          </Link>
        </Box>
        <Box py={2} align="center">
          <Typography variant="body2" color="textSecondary">
            Please enter security code from your email.
          </Typography>
        </Box>
        <ResetPasswordForm />
      </Content>
    </>
  );
};

export default ResetPassword;
